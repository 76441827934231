import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import LandingPage from "./pages/landingpage";
import Loginpf from "./pages/login";
import Signuppf from "./pages/signup";
import Tools from "./pages/tools";
import Checkout from "./pages/checkout";
import PrivateRoute from "./section-pages/pf/privateroute";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const routes = [
  { path: "/", element: <LandingPage /> },
  { path: "/landingpage", element: <LandingPage /> },
  { path: "/login", element: <Loginpf /> },
  { path: "/signup", element: <Signuppf /> },
  {
    path: "/tools",
    element: (
      <PrivateRoute>
        <Tools />
      </PrivateRoute>
    ),
  },
  {
    path: "/checkout",
    element: (
      <PrivateRoute>
        <Checkout />
      </PrivateRoute>
    ),
  },
];

const Navigation = () => (
  <Routes>
    {routes.map(({ path, element }) => (
      <Route key={path} path={path} element={element} />
    ))}
  </Routes>
);

function App() {
  return (
    <HelmetProvider>
      <div>
        <BrowserRouter>
          <ScrollToTop />
          <Navigation />
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;
