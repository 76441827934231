import React from "react";

const Section = () => {
  return (
    <>
  
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="subtitle mb-3">Why Go PrimeFussion?</div>
            <h2 className="mb-5">Smart Choice for Premium Access</h2>
          </div>

          <div className="col-lg-3 col-md-6 mb-sm-20">
            <div>
              <img src="./img/icons/icon-rocket.svg" className="mb20" alt="" />
              <h4>Instant Access</h4>
              <p>
                Get immediate entry to all your favorite premium apps with just
                one click. No waiting, just start enjoying!
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mb-sm-20">
            <div>
              <img src="./img/icons/icon-babi.svg" className="mb20" alt="" />
              <h4>Budget-Friendly</h4>
              <p>
                Our all-in-one subscription
                saves you money while giving you access to top-tier apps.
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mb-sm-20">
            <div>
              <img src="./img/icons/icon-secure.svg" className="mb20" alt="" />
              <h4>Secure & Private</h4>
              <p>
                Your data is safe with us. Enjoy seamless access without
                compromising your privacy or security.
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mb-sm-20">
            <div>
              <img src="./img/icons/icon-24h.svg" className="mb20" alt="" />
              <h4>Support 24/7</h4>
              <p>
                Our support team is ready 24/7 to help you with any issues or
                questions. We’ve got your back anytime, anywhere.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section;
