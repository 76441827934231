import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Parallax } from "react-parallax";
import Navbar from "../layout/Navbarrlpf";
import Preloader from "../layout/preloader";
import Footer from "../section-pages/pf/footerrlpf";
import ScrollToTopBtn from "../layout/ScrollToTop";

const image1 = "../img/background/bgtools.png";

export default function Checkout() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("mainpreloader");
      if (loader)
        setTimeout(() => {
          loader.classList.add("fadeOut");
          loader.style.display = "none";
        }, 600);
    }
  }, []);

  return (
    <>
      {/* HEAD */}
      <Helmet>
        <link rel="icon" href="/img/icon.png" />
        <title>Tools | Primefussion - Gateway to Premium Access!</title>
      </Helmet>

      {/* LOADER */}
      <div id="mainpreloader">
        <Preloader />
      </div>

      {/* MENU */}
      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>

        {/* section */}
        <Parallax className="" bgImage={image1} strength={5}>
          <section className="no-bg no-bottom">
            <div className="container d-flex justify-content-between">
              <div className="subtitle mb-5">Checkout Primefussion</div>
              <div className="">
                <a href="/" className="ml-auto btn-line">
                  Dashboard
                </a>
              </div>
            </div>
          </section>
        </Parallax>

        <section className="no-top checkout-section mb-5 mt-5">
          <div className="container">
            <div className="row">
              {/* Left Section: Premium Info */}
              <div className="col-md-7">
                <div className="card premium-card mb-4">
                  <div className="premium-ket d-flex justify-content-between">
                    <div className="premium-id">#WORKING30D</div>
                    <div className="premium-expiry">Expires: 12/31/2024</div>
                  </div>
                  <div className="premium-type">
                    <h4>Premium</h4>
                    <p>Working 30 Days</p>
                  </div>
                </div>
              </div>

              {/* Right Section: Summary, Payment, and Payment Method */}
              <div className="col-md-5">
                <div className="summary-card p-4 mb-4 text-center">
                  <div className="text-start">
                    <h3>Your order summary</h3>
                    <div className="d-flex justify-content-between mb-4 mt-4">
                      <p>Plan Working 30D</p>
                      <h4 className="price">
                        RP. 85.000<span>/30 Days</span>{" "}
                      </h4>
                    </div>
                  </div>
                  <hr />

                  {/* Payment Method Section */}
                  <div className="payment-method text-start">
                    <h4>Choose Payment Method</h4>

                    <div className="method-container">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="paymentMethod"
                          id="bankTransfer"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="bankTransfer"
                        >
                          <img
                            height="30"
                            src="/img/payments/iconpayment-qris-2.png"
                            alt="Bank Transfer"
                            className="payment-logo"
                          />
                        </label>
                      </div>
                    </div>

                    <div className="method-container">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="paymentMethod"
                          id="creditCard"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="creditCard"
                        >
                          <img
                            height="30"
                            src="/img/payments/iconpayment-dana-2.png"
                            alt="Credit Card"
                            className="payment-logo"
                          />
                        </label>
                      </div>
                    </div>

                    <div className="method-container">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="paymentMethod"
                          id="digitalWallet"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="digitalWallet"
                        >
                          <img
                            height="30"
                            src="/img/payments/iconpayment-ovo-2.png"
                            className="payment-logo"
                            alt="DANA Payment"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Voucher Code"
                    />
                    <a className="btn-main px-4 ml10">Apply</a>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <h4>Total</h4>
                    <h4 className="price">RP. 85.000,-</h4>
                  </div>
                  <hr />
                  <a className="btn-main w-50 mx-auto">Pay Now</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
      <ScrollToTopBtn />
    </>
  );
}
