import React from "react";
import { Link as ScrollLink } from "react-scroll"; // Link untuk scroll dalam halaman
import { Link as RouterLink } from "react-router-dom"; // Link untuk navigasi antar halaman

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="container">
        <div className="row gx-5">
          <div className="col-lg-6">
            <img src="/img/logo.png" alt="" />
            <div className="spacer-20"></div>
            <p>
              Dedicated to making premium access easy and affordable. Whether
              you’re binging the latest series or tackling a big project, our
              single subscription unlocks everything you need—from top-tier
              entertainment to essential productivity tools.
            </p>
          </div>
          <div className="col-lg-3">
            <div className="row">
              <div className="col-lg-6 col-sm-6">
                <div className="widget">
                  <h5>Shortcut</h5>
                  <ul>
                    <li>
                      <ScrollLink to="home" smooth={true} duration={500}>
                        Home
                      </ScrollLink>
                    </li>
                    <li>
                      <ScrollLink to="about" smooth={true} duration={500}>
                        About
                      </ScrollLink>
                    </li>
                    <li>
                      <ScrollLink to="pricing" smooth={true} duration={500}>
                        Price
                      </ScrollLink>
                    </li>
                    <li>
                      <ScrollLink to="listapps" smooth={true} duration={500}>
                        List Apps
                      </ScrollLink>
                    </li>
                    <li>
                      <ScrollLink to="faq" smooth={true} duration={500}>
                        FAQ
                      </ScrollLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="widget">
                  <h5>Tools</h5>
                  <ul>
                    <li>
                      <RouterLink to="/dashboard">Dashboard</RouterLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="widget">
              <div className="widget">
                <h5>Follow Us on</h5>
                <div className="social-icons">
                  <RouterLink to="/">
                    <i className="fa-brands fa-instagram"></i>
                  </RouterLink>
                  <RouterLink to="/">
                    <i className="fa-brands fa-tiktok"></i>
                  </RouterLink>
                  <RouterLink to="/">
                    <i className="fa-brands fa-discord"></i>
                  </RouterLink>
                  <RouterLink to="/">
                    <i className="fa-brands fa-twitter"></i>
                  </RouterLink>
                  <RouterLink to="/">
                    <i className="fa-brands fa-youtube"></i>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="subfooter">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              Copyright {currentYear} - PrimeFussion
            </div>
            <div className="col-lg-6 col-sm-6 text-lg-end text-sm-start">
              <ul className="menu-simple">
                <li>
                  <RouterLink to="/">Powered by EVV Laboratory</RouterLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
