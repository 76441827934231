import React from "react";

const Payment = () => {
  return (
    <div className="container">
      <div className="row align-items-center mt-4 mb-4 justify-content-end">
        <div className="col-lg-6">
          {/* <div className="col-sm-12 col-8 d-flex justify-content-end">
            <div className="subtitle wow fadeInUp mb-3">Payment Methods</div>
          </div> */}
          <div className="row g-4 d-flex justify-content-end">
            <div className="col-sm-2 col-4">
              <div className="p-2 rounded-10 bg-half-white">
                <img
                  width="70"
                  height="50"
                  src="/img/payments/iconpayment-qris.webp"
                  className="img-fluid"
                  alt="QRIS Payment"
                />
              </div>
            </div>
            <div className="col-sm-2 col-4">
              <div className="p-2 rounded-10 bg-half-white">
                <img
                  width="70"
                  height="50"
                  src="/img/payments/iconpayment-spay.webp"
                  className="img-fluid"
                  alt="ShopeePay Payment"
                />
              </div>
            </div>
            <div className="col-sm-2 col-4">
              <div className="p-2 rounded-10 bg-half-white">
                <img
                  width="70"
                  height="50"
                  src="/img/payments/iconpayment-ovo.webp"
                  className="img-fluid"
                  alt="OVO Payment"
                />
              </div>
            </div>
            <div className="col-sm-2 col-4">
              <div className="p-2 rounded-10 bg-half-white">
                <img
                  width="70"
                  height="50"
                  src="/img/payments/iconpayment-dana.webp"
                  className="img-fluid"
                  alt="DANA Payment"
                />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <p className="text-end text-muted">
              Your payment is secure with <a href="https://tripay.co.id"><span>Tripay.co.id</span></a>, ensuring safe and reliable transactions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
