import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Parallax } from "react-parallax";
import { Link, useNavigate } from "react-router-dom"; // Ganti useHistory dengan useNavigate
import axios from "axios";
import Navbar from "../layout/Navbarrlpf";
import Preloader from "../layout/preloader";
import ScrollToTopBtn from "../layout/ScrollToTop";
import { createGlobalStyle } from "styled-components";

const image1 = "../img/background/bglogin.png";

const GlobalStyles = createGlobalStyle`
  .react-parallax-bgimage {
    transform: translate3d(-50%, 0, 0px) !important;
  }
  .h-100{
    height: 100vh !important;
  }
`;

export default function Home() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Ganti useHistory dengan useNavigate

  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("mainpreloader");
      if (loader)
        setTimeout(() => {
          loader.classList.add("fadeOut");
          loader.style.display = "none";
        }, 600);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/login`, {
        email,
        password,
      });
      localStorage.setItem("token", response.data.token);
      navigate("/tools"); // Gunakan navigate untuk mengarahkan pengguna
    } catch (error) {
      setError(error.response ? error.response.data.message : "Login failed");
    }
  };

  return (
    <>
      {/* HEAD */}
      <Helmet>
        <link rel="icon" href="./img/icon.png" />
        <title>Login | Primefussion - Gateway to Premium Access!</title>
      </Helmet>

      <GlobalStyles />

      {/* LOADER */}
      <div id="mainpreloader">
        <Preloader />
      </div>

      {/* MENU */}
      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>

        {/* section */}
        <Parallax className="" bgImage={image1} strength={5}>
          <div className="de-gradient-edge-top"></div>
          <div className="de-gradient-edge-bottom"></div>
          <section className="no-bg">
            <div className="container z-9">
              <div className="row align-items-center">
                <div className="col-lg-4 offset-lg-4">
                  <div
                    className="padding40 pt60 pb60 rounded-20 shadow-soft bg-dark-1"
                    id="login"
                  >
                    <div className="text-center mb30">
                      <h4>Welcome back! Let's get you signed in</h4>
                    </div>
                    <div className="spacer-10"></div>
                    <form id="form_register" className="form-border" onSubmit={handleSubmit}>
                      <div className="field-set">
                        <label>Username or email</label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="field-set">
                        <label>Password</label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          className="form-control"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>

                      {error && <p style={{ color: "red" }}>{error}</p>}

                      <div className="spacer-20"></div>
                      <div id="submit">
                        <input
                          id="send_message"
                          type="submit"
                          value="Sign In"
                          className="btn-main btn-fullwidth rounded-3"
                        />
                      </div>
                    </form>
                    <div className="text-center mt-3">
                      <p>
                        New here?{" "}
                        <Link to="/signup" className="text-primary">
                          Join us
                        </Link>{" "}
                        &nbsp; and start the fun!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Parallax>
      </div>
      <ScrollToTopBtn />
    </>
  );
}
