import React from "react";
import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="container">
        <div className="row gx-5">
          <div className="col-lg-6">
            <img src="/img/logo.png" alt="" />
            <div className="spacer-20"></div>
            <p>
              Dedicated to making premium access easy and affordable. Whether
              you’re binging the latest series or tackling a big project, our
              single subscription unlocks everything you need—from top-tier
              entertainment to essential productivity tools.
            </p>
          </div>
          <div className="col-lg-3">
            <div className="row">
              <div className="col-lg-6 col-sm-6">
                <div className="widget">
                  <h5>Shortcut</h5>
                  <ul>
                    <li>
                      <RouterLink to="/">Home</RouterLink>
                    </li>
                    <li>
                      <RouterLink to="/">About</RouterLink>
                    </li>
                    <li>
                      <RouterLink to="/">Price</RouterLink>
                    </li>
                    <li>
                      <RouterLink to="/">List Apps</RouterLink>
                    </li>
                    <li>
                      <RouterLink to="/">FAQ</RouterLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="widget">
                  <h5>Tools</h5>
                  <ul>
                    <li>
                      <RouterLink to="/tools">Dashboard</RouterLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="widget">
              <div className="widget">
                <h5>Follow Us on</h5>
                <div className="social-icons">
                  <RouterLink to="/">
                    <i className="fa-brands fa-instagram"></i>
                  </RouterLink>
                  <RouterLink to="/">
                    <i className="fa-brands fa-tiktok"></i>
                  </RouterLink>
                  <RouterLink to="/">
                    <i className="fa-brands fa-discord"></i>
                  </RouterLink>
                  <RouterLink to="/">
                    <i className="fa-brands fa-twitter"></i>
                  </RouterLink>
                  <RouterLink to="/">
                    <i className="fa-brands fa-youtube"></i>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="subfooter">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              Copyright {currentYear} - PrimeFussion
            </div>
            <div className="col-lg-6 col-sm-6 text-lg-end text-sm-start">
              <ul className="menu-simple">
                <li>
                  <RouterLink to="/">Powered by EVV Laboratory</RouterLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
