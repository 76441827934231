import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Parallax } from "react-parallax";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Navbar from "../layout/Navbarrlpf";
import Preloader from "../layout/preloader";
import ScrollToTopBtn from "../layout/ScrollToTop";
import { createGlobalStyle } from "styled-components";

const image1 = "../img/background/bglogin.png";

const GlobalStyles = createGlobalStyle`
  .react-parallax-bgimage {
    transform: translate3d(-50%, 0, 0px) !important;
  }
`;

export default function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("mainpreloader");
      if (loader)
        setTimeout(() => {
          loader.classList.add("fadeOut");
          loader.style.display = "none";
        }, 600);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== rePassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/user/register`, {
        name,
        email,
        password,
      });

      Swal.fire({
        title: 'Registrasi Berhasil!',
        text: 'Akun Anda telah berhasil dibuat.',
        icon: 'success',
        background: '#010916', // Ubah background di sini
        color: '#ffffff', // Ubah warna teks jika perlu
        confirmButtonText: 'OK',
        confirmButtonColor: '#97FB4D'
      }).then(() => {
        navigate("/login");
      });

    } catch (error) {
      setError(error.response ? error.response.data.message : "Registration failed");
    }
  };

  return (
    <>
      <Helmet>
        <link rel="icon" href="./img/icon.png" />
        <title>Sign Up | Primefussion - Gateway to Premium Access!</title>
      </Helmet>

      <GlobalStyles />

      <div id="mainpreloader">
        <Preloader />
      </div>

      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>

        <Parallax className="" bgImage={image1} strength={5}>
          <div className="de-gradient-edge-top"></div>
          <div className="de-gradient-edge-bottom"></div>
          <section className="no-bg">
            <div className="container z-9">
              <div className="row align-items-center">
                <div className="col-lg-8 offset-lg-2">
                  <div
                    className="padding40 pt60 rounded-20 shadow-soft bg-dark-1"
                    id="login"
                  >
                    <form
                      name="contactForm"
                      id="contact_form"
                      className="form-border"
                      onSubmit={handleSubmit}
                    >
                      <h4>Join the Fun! Create Your Account Now</h4>
                      <p>
                        Welcome to PrimeFussion, where premium access is just a
                        sign-up away. Get ready to dive into a world of top-tier
                        entertainment and productivity tools—all in one place.
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="field-set">
                            <label>Name:</label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className="form-control"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="field-set">
                            <label>Email Address:</label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="form-control"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="field-set">
                            <label>Password:</label>
                            <input
                              type="password"
                              name="password"
                              id="password"
                              className="form-control"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="field-set">
                            <label>Re-enter Password:</label>
                            <input
                              type="password"
                              name="re-password"
                              id="re-password"
                              className="form-control"
                              value={rePassword}
                              onChange={(e) => setRePassword(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        {error && <p style={{ color: "red" }}>{error}</p>}

                        <div className="col-lg-6 offset-lg-3 text-center my-2">
                          <div id="submit">
                            <input
                              type="submit"
                              id="send_message"
                              value="Register Now"
                              className="btn-main color-2"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="text-center mt-2">
                      <p>
                        Already with us?{" "}
                        <Link to="/login" className="text-primary">
                          Log in here
                        </Link>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Parallax>
      </div>
      <ScrollToTopBtn />
    </>
  );
}
