import React from "react";
import { Parallax } from "react-parallax";
import YouTubePlayer from "./youtubeplayer";
import { Link } from "react-router-dom";

const Section = () => {
  const videoId = "P1CabD-8ULc";
  return (
    <Parallax className="home-video">
      <div className="iframeyoutube">
        <YouTubePlayer videoId={videoId} />
      </div>

      <div className="de-gradient-edge-top"></div>
      <div className="de-gradient-edge-bottom"></div>
      <div className="overlay-bg t50 no-top no-bottom">
        <div className="v-center">
          <div className="container z-1000">
            <div className="row align-items-center">
              <div className="col-lg-10 offset-lg-1 text-center">
                <div className="subtitle blink mb-3">
                Hey There, Welcome!
                </div>
                <h1 className="mb-1 ">PrimeFussion</h1>
              </div>
              <div className="col-lg-6 offset-lg-3 text-center text-white">
                <p className="">
                  Tired of juggling multiple subscriptions for your
                  favorite apps?
                </p>
                <hr />
                <p>
                With PrimeFussion, you get all the premium vibes in one place. From binge-worthy shows to pro-level productivity tools, we’ve got you covered—all for a price that won’t break the bank.
                </p>
                <Link className="btn-main mt-3" href="#">
                  Check the Demo and See the Magic
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Parallax>
  );
};

export default Section;
