import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Parallax } from "react-parallax";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Navbar from "../layout/Navbarrlpf";
import Preloader from "../layout/preloader";
import Footer from "../section-pages/pf/footerrlpf";
import ScrollToTopBtn from "../layout/ScrollToTop";
import { Modal, Button } from "react-bootstrap";

const image1 = "../img/background/bgtools.png";

export default function Home() {
  const [userData, setUserData] = useState({
    name: "",
    isPremium: false,
    premiumExpiry: "",
    planName: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [showManualModal, setShowManualModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // State untuk menyimpan data manual
  const [manualData, setManualData] = useState({
    name: "",
    email: "",
    password: "",
    url: "",
    deskripsi: "",
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("mainpreloader");
      if (loader)
        setTimeout(() => {
          loader.classList.add("fadeOut");
          loader.style.display = "none";
        }, 600);
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) return;

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/me`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setUserData(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Hapus token dan arahkan ke login jika sesi tidak valid
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          console.error("Failed to fetch user data:", error);
        }
      }
    };

    fetchUserData();
  }, []);

  // Ambil query parameters dari URL
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const name = queryParams.get("name");
    const email = queryParams.get("login");
    const password = queryParams.get("password");
    const url = queryParams.get("url");
    const deskripsi = queryParams.get("deskripsi");

    if (name && email && password && url && deskripsi) {
      setManualData({ name, email, password, url, deskripsi });
      setShowManualModal(true);

      const urlWithoutParams = location.pathname;
      navigate(urlWithoutParams, { replace: true });
    }
  }, [location.search, navigate, location.pathname]);

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("token");

      if (token) {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/user/logout`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }

      // Hapus token dari localStorage
      localStorage.removeItem("token");

      // Arahkan ke halaman login
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert("Copied to clipboard");
    });
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseManualModal = () => {
    setShowManualModal(false);

    const urlWithoutParams = location.pathname;
    navigate(urlWithoutParams, { replace: true });
  };

  return (
    <>
      {/* HEAD */}
      <Helmet>
        <link rel="icon" href="/img/icon.png" />
        <title>Tools | Primefussion - Gateway to Premium Access!</title>
      </Helmet>

      {/* LOADER */}
      <div id="mainpreloader">
        <Preloader />
      </div>

      {/* MENU */}
      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>

        {/* section */}
        <Parallax className="" bgImage={image1} strength={5}>
          <section className="no-bg no-bottom">
            <div className="container">
              <div className="subtitle mb-2">Tools Dashboard PrimeFussion</div>
              <div className="row">
                <div className="col-lg-12 text-center position-relative mb10">
                  <div className="profile-card">
                    <div className="profile-card__profile">
                      <div className="hello">Hallo 👋</div>
                      <h2 className="name">{userData.name}</h2>
                    </div>
                    <div className="profile-card__role">
                      <div className="exp">
                        <h4>
                          {userData.isPremium ? "Premium User" : "Free User"}
                        </h4>
                        <p>
                          {userData.isPremium &&
                            `Until ${new Date(
                              userData.premiumExpiry
                            ).toLocaleDateString()}`}
                        </p>
                      </div>
                      <h3>
                        {userData.planName ? userData.planName : "No Plan"}
                      </h3>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </section>
        </Parallax>

        <section className="no-top tools-section mb-5 mt-5">
          <div className="container">
            <div className="row">
              <div className="tool-item d-flex">
                <i className="fa fa-lock" aria-hidden="true"></i>
                <p>Reset Password</p>
              </div>
              <div className="tool-item d-flex">
                <i className="fa fa-credit-card" aria-hidden="true"></i>
                <p>Manage Subscription</p>
              </div>
              <div
                className="tool-item d-flex"
                onClick={handleShowModal}
                style={{ cursor: "pointer" }}
              >
                <i className="fa fa-wrench" aria-hidden="true"></i>
                <p>Tutorial Installation</p>
              </div>
              <div className="tool-item d-flex">
                <i className="fa fa-cog" aria-hidden="true"></i>
                <p>Account Settings</p>
              </div>
              {/* Tombol Logout */}
              <div
                className="tool-item d-flex"
                onClick={handleLogout}
                style={{ cursor: "pointer" }}
              >
                <i className="fa fa-sign-out" aria-hidden="true"></i>
                <p>Logout</p>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
      <ScrollToTopBtn />

      {/* Modal untuk Manual Data */}
      <Modal
      show={showManualModal}
      onHide={handleCloseManualModal}
      centered
      className="custom-modal-manual"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {manualData.name && (
            <div className="name-apps mb-1">
              <h3>{manualData.name}</h3>
            </div>
          )}
        </Modal.Title>
        {manualData.deskripsi && <p>{manualData.deskripsi}</p>}
      </Modal.Header>
      <Modal.Body>
        {manualData.email && (
          <div className="email d-flex align-items-center mb-1">
            <p className="mb-0">
              <strong>Email</strong> : {manualData.email}
            </p>
            <i
              className="fa fa-copy ml-2"
              style={{ cursor: "pointer" }}
              onClick={() => copyToClipboard(manualData.email)}
              title="Copy Email"
            />
          </div>
        )}

        {manualData.password && (
          <div className="password d-flex align-items-center">
            <p className="mb-0">
              <strong>Password</strong> : {manualData.password}
            </p>
            <i
              className="fa fa-copy ml-2"
              style={{ cursor: "pointer" }}
              onClick={() => copyToClipboard(manualData.password)}
              title="Copy Password"
            />
          </div>
        )}

        {manualData.url && manualData.name && (
          <a
            className="btn-main mt30"
            href={manualData.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            PERGI KE {manualData.name}
          </a>
        )}
      </Modal.Body>
    </Modal>

      {/* Modal untuk Tutorial Installation */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
        centered
        scrollable
        className="custom-modal"
      >
        <Modal.Header>
          <Modal.Title>Tutorial Installation</Modal.Title>
          <p>Welcome to the installation guide!</p>
        </Modal.Header>
        <Modal.Body>
          {/* Embed Video YouTube */}
          <div className="embed-responsive embed-responsive-16by9 mb-3">
            <iframe
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/xDPbzPOOOB8?si=HOtyZFu7r2VewjhU&amp;controls=0"
              allowFullScreen
              title="YouTube Video Tutorial"
            ></iframe>
          </div>

          <h5>Step 1: Download the Extension</h5>
          <p>
            Click the button below to download the extension files from Google
            Drive.
          </p>
          <Button
            variant="primary"
            href="https://drive.google.com/drive/folders/1xFfHarTemZiIBoRoVIr06RnCiSQ6ksRU?usp=sharing"
            target="_blank"
          >
            Download Extension
          </Button>

          <h5 className="mt-4">Step 2: Extract the Downloaded Files</h5>
          <p>
            After downloading, extract the ZIP file to a location on your
            computer where you can easily find it.
          </p>

          <h5 className="mt-4">Step 3: Enable Developer Mode in Chrome</h5>
          <p>
            Open Chrome and navigate to <strong>chrome://extensions/</strong>.
            In the top-right corner, toggle the switch to enable Developer Mode.
          </p>

          <h5 className="mt-4">Step 4: Load the Extension</h5>
          <p>
            Back in the Chrome Extensions page, click on{" "}
            <strong>Load unpacked</strong>, then navigate to the folder where
            you extracted the files. Select the folder and click "Open."
          </p>

          <h5 className="mt-4">Step 5: Start Using the Extension</h5>
          <p>
            The extension should now be loaded and ready to use. You can start
            exploring its features by clicking on its icon in the Chrome
            toolbar.
          </p>

          <p>
            If you encounter any issues during the setup, please refer to the
            video above or reach out to our support team.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}
