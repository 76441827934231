// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #5623d8;
  --primary-color-rgb: 86, 35, 216;
  --secondary-color: #6a79fa;
  --secondary-color-rgb: 106, 121, 250;
  --tertiary-color: #e615af;
  --tertiary-color-rgb: rgba(230, 21, 175, 1.0);
}`, "",{"version":3,"sources":["webpack://./src/assets/maincolor.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,gCAAgC;EAChC,0BAA0B;EAC1B,oCAAoC;EACpC,yBAAyB;EACzB,6CAA6C;AAC/C","sourcesContent":[":root {\n  --primary-color: #5623d8;\n  --primary-color-rgb: 86, 35, 216;\n  --secondary-color: #6a79fa;\n  --secondary-color-rgb: 106, 121, 250;\n  --tertiary-color: #e615af;\n  --tertiary-color-rgb: rgba(230, 21, 175, 1.0);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
