import React from "react";

const About = () => {
  return (
    <section className="about-section">
      <div className="container">
        <div className="row align-items-center gx-5">
          <div className="col-lg-6 position-relative">
            <div className="images-deco-1">
              <img src="/img/misc/pf1x.png" className="d-img-1" alt="" />
              <img src="/img/misc/1pff.png" className="d-img-2" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="subtitle mb20">About Us</div>
            <h2>Revolutionizing Premium Access</h2>
            <p>
              PrimeFussion, dedicated to making premium access both
              easy and affordable. Whether you’re binging the latest series or
              knocking out a big project, our single subscription unlocks
              everything you need—from top-tier entertainment to essential
              productivity tools. No more juggling multiple accounts or breaking
              the bank; we’ve got it all covered in one convenient package.
            </p>
            <p>
              We’re all about delivering value without the hassle. That’s why we
              provide not just access, but a smooth, enjoyable experience backed
              by 24/7 support. PrimeFussion is your go-to for all things
              premium, constantly bringing you the latest content, all at a
              wallet-friendly price.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
