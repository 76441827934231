import React, { useEffect, useState, useRef } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";

const NavLink = ({ to, ...props }) => {
  return to.startsWith("/") ? (
    <RouterLink to={to} {...props} />
  ) : (
    <ScrollLink to={to} smooth={true} duration={500} {...props} />
  );
};

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if the user is logged in
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token); // Set isLoggedIn to true if token exists
  }, []);

  const useDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
      setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const closeDropdown = () => {
      setIsOpen(false);
    };

    const ref = useRef(null);
    useOnclickOutside(() => {
      closeDropdown();
    }, ref);

    return {
      isOpen,
      toggleDropdown,
      closeDropdown,
      ref,
    };
  };

  const {
    toggleDropdown: handleBtnClick,
    closeDropdown: closeMenu,
    ref,
  } = useDropdown();

  const [showmenu, setBtnIcon] = useState(false);

  useEffect(() => {
    const header = document.getElementById("header-wrap");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;

    const scrollCallBack = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");
      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      }
    };

    window.addEventListener("scroll", scrollCallBack);

    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  return (
    <nav className="navbar transition">
      <div className="container">
        {/********* Logo *********/}
        <NavLink className="navbar-brand" to="home">
          <img
            src="/img/logo.png"
            className="img-fluid d-md-block d-none imginit"
            alt="logo"
          />
          <img
            src="/img/logo-mobile.png"
            className="img-fluid d-md-none d-sms-none imginit"
            alt="logo"
          />
        </NavLink>
        {/********* Logo *********/}

        {/********* Mobile Menu *********/}
        <div className="mobile">
          {showmenu && (
            <div className="menu">
              <div className="navbar-item counter">
                <div ref={ref}>
                  <NavLink to="home" onClick={() => setBtnIcon(!showmenu)}>
                    Home
                  </NavLink>
                </div>
              </div>

              <div className="navbar-item counter">
                <NavLink to="section1" onClick={() => setBtnIcon(!showmenu)}>
                  Feature
                </NavLink>
              </div>

              <div className="navbar-item counter">
                <NavLink to="about" onClick={() => setBtnIcon(!showmenu)}>
                  About
                </NavLink>
              </div>

              <div className="navbar-item counter">
                <NavLink to="pricing" onClick={() => setBtnIcon(!showmenu)}>
                  Pricing
                </NavLink>
              </div>

              <div className="navbar-item counter">
                <NavLink to="listapps" onClick={() => setBtnIcon(!showmenu)}>
                  Applications
                </NavLink>
              </div>

              <div className="navbar-item counter">
                <NavLink to="faq" onClick={() => setBtnIcon(!showmenu)}>
                  FAQ
                </NavLink>
              </div>
            </div>
          )}
        </div>
        {/********* Mobile Menu *********/}

        {/********* Dekstop Menu *********/}
        {!showmenu && (
          <div className="dekstop">
            <div className="menu">
              <div className="navbar-item counter">
                <NavLink
                  to="home"
                  onMouseEnter={handleBtnClick}
                  onMouseLeave={closeMenu}
                >
                  Home
                </NavLink>
              </div>

              <div className="navbar-item counter">
                <NavLink
                  to="section1"
                  onMouseEnter={handleBtnClick}
                  onMouseLeave={closeMenu}
                >
                  Feature
                </NavLink>
              </div>

              <div className="navbar-item counter">
                <NavLink to="about">About</NavLink>
              </div>

              <div className="navbar-item counter">
                <NavLink to="pricing">Pricing</NavLink>
              </div>

              <div className="navbar-item counter">
                <NavLink to="listapps">Applications</NavLink>
              </div>

              <div className="navbar-item counter">
                <NavLink to="faq">FAQ</NavLink>
              </div>
            </div>
          </div>
        )}
        {/********* Dekstop Menu *********/}

        {/********* Side Button *********/}
        <div className="menu_side_area">
          <div className="auth-btn">
            {isLoggedIn ? (
              <>
                <NavLink to="/tools" className="btn-line" id="btn-line">
                  Dashboard
                </NavLink>
              </>
            ) : (
              <>
                <NavLink to="/signup" className="btn-line" id="btn-line">
                  Sign Up
                </NavLink>
                <NavLink to="/login" className="btn-full" id="btn-full">
                  Login
                </NavLink>
              </>
            )}
          </div>
          {/********* Burger Button *********/}
          <button
            className="burgermenu"
            type="button"
            onClick={() => setBtnIcon(!showmenu)}
          >
            <i className="fa fa-bars" aria-hidden="true"></i>
          </button>
          {/********* Burger Button *********/}
        </div>
        {/********* Side Button *********/}
      </div>
    </nav>
  );
};

export default Navbar;
