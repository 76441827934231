import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Parallax } from "react-parallax";
import Navbar from "../layout/Navbarpf";
import Preloader from "../layout/preloader";
import Homestatic from "../section-pages/pf/homestatic-video";
import Section1 from "../section-pages/pf/section-1-center";
import Pricelist from "../section-pages/pf/pricelist-1";
import Help from "../section-pages/pf/help-center";
import Payment from "../section-pages/pf/Payment";
import Footer from "../section-pages/pf/footerpf";
import ScrollToTopBtn from "../layout/ScrollToTop";
import About from "../section-pages/pf/about";
import Listapps from "../section-pages/pf/listapps";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  
`;

const image3 = "/img/background/pf1.png";
const image1 ="/img/background/pf2.png";

export default function Home() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("mainpreloader");
      if (loader)
        setTimeout(() => {
          loader.classList.add("fadeOut");
          loader.style.display = "none";
        }, 600);
    }
  }, []);

  return (
    <>
      <Helmet>
        <link rel="icon" href="/img/icon.png" />
        <title>Primefussion - Gateway to Premium Access!</title>
      </Helmet>

      <GlobalStyles />

      <div id="mainpreloader">
        <Preloader />
      </div>

      <div className="home dark-scheme">
        <header id="header-wrap">
          <Navbar />
        </header>

        <section id="home" className="no-padding no-bottom">
          <Homestatic />
        </section>

        <section id="section1" className="no-top no-bottom tex-center">
          <Section1 />
        </section>

        <section id="about" className="no-bottom no-top">
          <About />
        </section>

        <Parallax className="" bgImage={image3} strength={150}>
          <section id="pricing" className="no-bg no-bottom no-top">
            <Pricelist />
          </section>
        </Parallax>

        <section id="payment" className="no-bottom no-top">
          <Payment />
        </section>

        <Parallax className="" bgImage={image1} strength={300}>
          <div className="de-gradient-edge-top"></div>
          <div className="de-gradient-edge-bottom"></div>
          <section id="listapps" className="no-bg no-top">
            <Listapps />
          </section>
        </Parallax>

        <section id="faq" className="no-top">
          <Help />
        </section>


        <Footer />
      </div>
      <ScrollToTopBtn />
    </>
  );
}
