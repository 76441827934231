import React from "react";
import { Link as RouterLink } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="navbar transition">
      <div className="container">
        {/********* Logo *********/}
        <RouterLink className="navbar-brand" to="/">
          <img
            src="/img/logo.png"
            className="img-fluid d-md-block d-none imginit"
            alt="logo"
          />
          <img
            src="/img/logo-mobile.png"
            className="img-fluid d-md-none d-sms-none imginit"
            alt="logo"
          />
        </RouterLink>
        {/********* Logo *********/}
      </div>
    </nav>
  );
};

export default Navbar;
