import React, { useState, useEffect } from "react";

const Listapps = () => {
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApps = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/apps`);
        if (response.ok) {
          const data = await response.json();
          setApps(data);
        } else {
          setError("Failed to fetch apps");
        }
      } catch (err) {
        setError("Error fetching apps");
      } finally {
        setLoading(false);
      }
    };

    fetchApps();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 mb-2">
          <div className="subtitle mb20">Applications</div>
          <h2 className="wow fadeInUp">Apps Collection</h2>
          <div className="spacer-20"></div>
        </div>
      </div>
      <div className="apps-grid">
        {apps.map((app) => (
          <div className="app-item" key={app.id}>
            <a href={app.link} target="_blank" rel="noopener noreferrer">
              <img src={app.icon} alt={app.name} className="app-icon mb-1" />
              <p className="app-name">{app.name}</p>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Listapps;
