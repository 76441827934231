import React from "react";

const Help = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="subtitle mb-3">Got Questions?</div>
          <h2 className="mb20">We’ve Got Answers!</h2>
        </div>

        <div className="clearfix"></div>

        <div className="col-lg-6">
          <div className="accordion accordion-flush" id="accflush">
            <div className="accordion-item">
              <h3 className="accordion-header" id="flush-h1">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-c1"
                  aria-expanded="false"
                  aria-controls="flush-c1"
                >
                  What is PrimeFussion?
                </button>
              </h3>
              <div
                id="flush-c1"
                className="accordion-collapse collapse"
                aria-labelledby="flush-h1"
                data-bs-parent="#accflush"
              >
                <div className="accordion-body">
                  <p>
                    PrimeFussion is your all-in-one subscription service that
                    lets you access multiple premium apps with just one account.
                    No more juggling between different subscriptions – we’ve got
                    you covered!
                  </p>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h3 className="accordion-header" id="flush-h2">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-c2"
                  aria-expanded="false"
                  aria-controls="flush-c2"
                >
                  How does this service work?
                </button>
              </h3>
              <div
                id="flush-c2"
                className="accordion-collapse collapse"
                aria-labelledby="flush-h2"
                data-bs-parent="#accflush"
              >
                <div className="accordion-body">
                  <p>
                    Once you subscribe, you'll get a browser extension. Pop that
                    into your browser, and boom—you’ve got access to all the
                    premium apps in your subscription package.
                  </p>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h3 className="accordion-header" id="flush-h3">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-c3"
                  aria-expanded="false"
                  aria-controls="flush-c3"
                >
                  What’s in PrimeFussion?
                </button>
              </h3>
              <div
                id="flush-c3"
                className="accordion-collapse collapse"
                aria-labelledby="flush-h3"
                data-bs-parent="#accflush"
              >
                <div className="accordion-body">
                  <p>
                    PrimeFussion gives you access to a variety of premium apps
                    across different categories—entertainment, productivity,
                    learning, and more. The lineup keeps changing, so check the
                    Product List for the latest options!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="accordion accordion-flush" id="accflush1">
            <div className="accordion-item">
              <h3 className="accordion-header" id="flush-h1-1">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-c1-1"
                  aria-expanded="false"
                  aria-controls="flush-c1-1"
                >
                  Can I switch my subscription plan?
                </button>
              </h3>
              <div
                id="flush-c1-1"
                className="accordion-collapse collapse"
                aria-labelledby="flush-h1-1"
                data-bs-parent="#accflush1"
              >
                <div className="accordion-body">
                  <p>
                    Nah, you can’t switch plans, but no biggie! You can just
                    grab another plan whenever you want, and boom—you’ll have
                    both plans running at the same time.
                  </p>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h3 className="accordion-header" id="flush-h2-1">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-c2-1"
                  aria-expanded="false"
                  aria-controls="flush-c2-1"
                >
                  Can I share my account?
                </button>
              </h3>
              <div
                id="flush-c2-1"
                className="accordion-collapse collapse"
                aria-labelledby="flush-h2-1"
                data-bs-parent="#accflush1"
              >
                <div className="accordion-body">
                  <p>
                    Nope, sorry! This one’s all yours. Sharing might seem like
                    caring, but not when it comes to PrimeFussion—keep it
                    personal!
                  </p>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h3 className="accordion-header" id="flush-h3-1">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-c3-1"
                  aria-expanded="false"
                  aria-controls="flush-c3-1"
                >
                  What if I need help or support?
                </button>
              </h3>
              <div
                id="flush-c3-1"
                className="accordion-collapse collapse"
                aria-labelledby="flush-h3-1"
                data-bs-parent="#accflush1"
              >
                <div className="accordion-body">
                  <p>
                    No worries, we’re here 24/7! Whether you’re dealing with a
                    tech glitch or just have a question about your account, our
                    support team’s got your back. Just hit us up, and we’ll make
                    sure your PrimeFussion experience stays awesome.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
